import service from '@/utils/request';

// @Tags DomainIdex
// @Summary 添加备注
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Domainindex true "添加备注"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /remark/:domain [put]
export var updateDomainRemark = function updateDomainRemark(data) {
  return service({
    url: "/domainindex/remark/" + data.dname,
    method: 'put',
    data: data
  });
};