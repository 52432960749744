import service from '@/utils/request';

// @Tags Hc
// @Summary 启用健康检查任务
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body int true "启用健康检查任务"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"操作成功"}"
// @Router /hc/enableHealthCheck [post]
export var enableHealthCheck = function enableHealthCheck(params) {
  return service({
    url: "/hc/enableHealthCheck/" + params.domain,
    method: 'post',
    params: params
  });
};

// @Tags Hc
// @Summary 禁用健康检查任务
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body int true "禁用健康检查任务"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"操作成功"}"
// @Router /hc/disableHealthCheck [post]
export var disableHealthCheck = function disableHealthCheck(params) {
  return service({
    url: "/hc/disableHealthCheck/" + params.domain,
    method: 'post',
    params: params
  });
};